<template>
  <div>
    <table class="default-table">
      <tbody>
        <tr v-for="(value, name) in data" :key="name">
          <template
            v-if="name !== 'json' && name !== 'raw' && name !== 'leadData'"
          >
            <td>{{ name }}</td>
            <td>{{ value }}</td>
          </template>
        </tr>
      </tbody>
    </table>

    <h2>JSON</h2>

    <table class="default-table">
      <tbody>
        <tr v-for="(value, name) in data.json" :key="name">
          <td>{{ name }}</td>
          <td>{{ value }}</td>
        </tr>
      </tbody>
    </table>

    <template v-if="data.leadId">
      <h2>LEAD-DATA</h2>

      <table class="default-table">
        <tbody>
          <tr v-for="(value, name) in data.leadData" :key="name">
            <td>{{ name }}</td>

            <td v-if="name === 'field_data'">
              <table class="default-table">
                <tbody>
                  <tr v-for="(lvalue, lname) in value" :key="lname">
                    <td>{{ value[lname].name }}</td>
                    <td>{{ value[lname].values }}</td>
                  </tr>
                </tbody>
              </table>
            </td>

            <td v-else>{{ value }}</td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "Webhook",
  components: {},
  props: ["data"],

  data() {
    return {};
  },

  created: function() {},

  methods: {
    parsedJson(string) {
      return JSON.parse(string);
    },
  },

  mixins: [apiRequest],
};
</script>
