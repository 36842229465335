<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8">
        <DataTable
          @tableAction="emittedAction"
          :items="items"
          :headers="headers"
          :itemActions="{
            edit: true,
          }"
          :preloader="preloader"
        />
      </v-col>
    </v-row>

    <v-dialog
      v-model="hookDialog"
      max-width="800px"
      transition="dialog-bottom-transition"
      scrollable
      persistent
      :key="componentKey"
    >
      <v-card>
        <v-toolbar dark color="primary" elevation="0">
          <v-toolbar-title>Webhook</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="hookDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
          <Webhook :data="itemData" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import DataTable from "@/components/ui/DataTable.vue";
import Webhook from "@/views/tech/debugging/facebook/webhooks/Webhook.vue";
export default {
  components: {
    PageHeader,
    DataTable,
    Webhook,
  },

  data() {
    return {
      hookDialog: false,
      componentKey: null,
      pageTitle: "Webhooks",
      pageDescription: "Facebook Web-Hooks Debugger",
      headers: [
        { text: "id", value: "id" },
        { text: "created", value: "created" },
        { text: "Field", value: "field" },
        { text: "leadgen_id", value: "leadgen_id" },
        { text: "object", value: "object" },
        { text: "leadId", value: "leadId" },
        { text: "ad_id", value: "json.ad_id" },
        { text: "form_id", value: "json.form_id" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      // leadData
      items: [],
      itemData: [],
      edit: true,
      trash: true,
      copy: false,
      search: "",
      preloader: false,
    };
  },

  created: function() {
    this.getData();
  },

  methods: {
    async getData() {
      this.preloader = true;

      /* USEING REUSEABLE MIXIN METHOD */
      let response = await this.getRequest("facebookWebhooks");
      this.preloader = false;

      if (response.status === 200) {
        this.items = response.data.data;
      }
    },

    emittedAction(value) {
      console.log(value);
      this.itemData = value.itemData;
      this.componentKey += 1;
      this.hookDialog = true;
    },
  },

  mixins: [apiRequest],
};
</script>
